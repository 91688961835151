// window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// window.axios = require('axios');

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Add jQuery support (until the site gets a rewrite, it's what we will use)
 */
import $ from 'jquery';
console.log('jQuery', $);
window.jQuery = window.$ = $;
console.log('Loaded jQuery as $');

import bootbox from 'bootbox';
window.bootbox = bootbox;
console.log('Loaded bootbox');

console.log('bootstrap.js loaded');

// import 'sorttable';
